import rollbarLogger from '../../../../../../../Templates/Shared2016/TS/RollbarLogger';
import { CryptoJS } from '../../../../../../../Core/Framework/JavaScript/CryptoJS.js';

('use strict');

/**
 * AuthDriverServiceLayer
 *
 * Place to host logic for the Auth driver.
 */
export class AuthDriverServiceLayer {
	/**
	 * @constructor
	 */
	constructor() {
		// XXX: It would be nice to have a better way of getting this information.
		this.Hook = $('AuthenticationLayer');

		this.servicelayer_url = this.Hook.getAttribute('data-servicelayer_url');
		this.dat_url = this.Hook.getAttribute('data-data_url');

		this.type = 'visitor';
	}

	/**
	 * Build response to login a user with password
	 *
	 * @param {object} data
	 * @param {String} data.type
	 * @param {String} data.salt
	 * @param {String} data.challenge
	 * @param {String} data.iterations
	 * @param {string} password_input
	 *
	 * @private
	 * @returns {String}
	 */
	_buildResponse(data, password_input) {
		var password = password_input;

		/*
			 We used to be content with sha1, this changed when the
			 fire nation attacked. Now we need to pre process the
			 password if we encounter an old password.
			 */

		if (data.type === 'sha1') {
			password = CryptoJS.SHA1(password).toString();
		}

		/*
			 Lets try to match the password the user typed to the one
			 in the database.
			 */

		password = CryptoJS.PBKDF2(password, data.salt, {
			keySize: 256 / 32,
			iterations: data.iterations,
			hasher: CryptoJS.algo.SHA256
		}).toString();

		// And then hide it in a sha265 hmac with the provided challenge.

		return CryptoJS.HmacSHA256(password, data.challenge).toString();
	}

	/**
	 * Get details needed for this login attempt.
	 *
	 * @param {string} username
	 * @param {Object} [options]
	 * @param {Function} [options.onSuccess]
	 * @private
	 */
	_getDetails(username, options) {
		options = options || {};

		var Call = new Request({
			url: this.servicelayer_url + 'auth/details/',
			method: 'get',
			data: {
				type: this.type,
				username
			},
			onSuccess($result) {
				$result = JSON.decode($result);

				if (typeof options.onSuccess === 'function') {
					options.onSuccess($result);
				}
			},
			onFailure(error) {
				rollbarLogger.logCritical('GET auth/details request failed', error);
			}
		});
		Call.queue();
	}

	/**
	 * Validate response on the SL.
	 *
	 * @param {string} username
	 * @param {string} response
	 * @param {Object} [options]
	 * @param {Function} [options.onSuccess]
	 * @param {Function} [options.onFailure]
	 *
	 * @private
	 * @returns void
	 */
	_validateResponse(username, response, options) {
		options = options || {};

		var request = new Request({
			method: 'post',
			url: this.servicelayer_url + 'auth/validate/',
			data: {
				username,
				response,
				persistent: 0,
				// Location is needed for token generation.
				location: this.dat_url,
				type: this.type
			},
			onSuccess($result) {
				$result = JSON.decode($result);

				if ($result['success'] === true) {
					if (typeof options.onSuccess === 'function') {
						options.onSuccess($result);
					}
				} else {
					if (typeof options.onFailure === 'function') {
						options.onFailure();
					}
				}
			},
			onFailure: options.onFailure
		});

		try {
			// WithCredentials to enable setting a persistent cookie.
			request.xhr.withCredentials = true;
		} catch (err) {
			// IE does not allow withCredentials here, do it without cookie.
			console.warn(err);
		} finally {
			request.queue();
		}
	}

	/**
	 *
	 * @param {String} $username
	 * @param {String} $password
	 * @param {Object} [options]
	 * @param {Function} [options.onSuccess]
	 * @param {Function} [options.onFailure]
	 *
	 * @protected
	 * @returns void;
	 */
	_loginSl($username, $password, options) {
		var $self = this;

		options = options || {};

		this._getDetails($username, {
			onSuccess(data) {
				$self._validateResponse($username, $self._buildResponse(data, $password), {
					onSuccess: options.onSuccess,
					onFailure: options.onFailure
				});
			}
		});
	}
}

(function (_w) {
	_w.AuthDriverServiceLayer = new AuthDriverServiceLayer();
})(window);
