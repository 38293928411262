/**
 * @author Rob van den Hout <vdhout@studyportals.eu>
 * @author Danko Adamczyk <danko@studyportals.com>
 * @author Iliq Nikushev <iliq@studyportals.com>
 * @version 2.3.0
 * @copyright © 2014-2016 StudyPortals B.V., all rights reserved.
 */

(function () {
	'use strict';

	var queue = [],
		running = false;

	/**
	 * Process the queue.
	 *
	 * <p>Get shift the first item from the queue and process it. When tis request is not executed for the first time
	 * (due to the retry param) it will add a retry param to the url and delay the request.</p>
	 */

	function processQueue() {
		if (running) {
			return;
		}

		var next = queue.shift();
		if (!next) {
			return;
		}

		running = true;
		if (next.retry > 0) {
			next.options.url = _addAttemptsToUrl(next.options.url, next.retry);

			setTimeout(function () {
				next.send();
			}, next.retry * next.delay);
		} else {
			next.send();
		}
	}

	/**
	 * Process completed request.
	 */

	function requestSuccess() {
		running = false;

		if (queue.length === 0) {
			this.fireEvent('onLastSuccess', this.xhr);
		}

		processQueue();
	}

	/**
	 * Handle the request load start event.
	 *
	 * <p>Increment the retry property</p>
	 */

	function requestLoadstart() {
		this.retry++;
	}

	/**
	 * Handle the request failed event.
	 *
	 * <p>If the failure is temporary (e.g. 503) and the request did not reach it max attempts it will be added to the
	 * queue again. Depending on the 'fixed' property it will be added in the start or end of the queue.</p>
	 * <p>When the request fails for the final time an onLastFailure event in thrown with the xhr as argument.</p>
	 * <p>Finanly the queue is processed again.</p>
	 */

	function requestFailed(response) {
		running = false;

		var request = this;

		request.xhr.retry = request.retry;

		if (response.status === 503 && request.retry < request.maxAttempts) {
			if (request.fixed === true) {
				queue.unshift(request);
			} else {
				queue.push(request);
			}
		} else {
			request.fireEvent('onLastFailure', request.xhr);
		}

		processQueue();
	}

	/**
	 * Handle the request cancel event.
	 *
	 * <p>Set running to false and process the queue.</p>
	 */

	function requestCanceled() {
		running = false;
		processQueue();
	}

	/**
	 * Add the attempts param to the url for logging purposes.
	 *
	 * @param url
	 * @param attempts
	 * @returns {string}
	 * @private
	 */

	function _addAttemptsToUrl(url, attempts) {
		const myURI = new URL(url);
		const query = new URLSearchParams(myURI.search);

		query.set('retry', attempts);
		myURI.search = query;

		return myURI.toString();
	}

	Request.implement({
		/**
		 * Queue implementation to handle synchronise requests.
		 *
		 * @param maxAttempts
		 * @param delay
		 * @param fixed
		 */

		queue: function (maxAttempts, delay, fixed) {
			this.maxAttempts = maxAttempts || 1;
			this.delay = delay || 1000;
			this.fixed = fixed === false;

			this.retry = this.xhr.retry = 0;

			this.removeEvent('loadstart', requestLoadstart);
			this.removeEvent('success', requestSuccess);
			this.removeEvent('failure', requestFailed);
			this.removeEvent('cancel', requestCanceled);

			this.addEvent('loadstart', requestLoadstart);
			this.addEvent('success', requestSuccess);
			this.addEvent('failure', requestFailed);
			this.addEvent('cancel', requestCanceled);

			queue.push(this);
			processQueue();
		}
	});
})();
