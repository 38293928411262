/**
 * @file Shared.js
 * For portal-wide usage; requires MooTools.
 *
 * @copyright © 2011-2017 StudyPortals B.V., all rights reserved.
 * @version 2.1.0
 */

'use strict';

/**
 * Container for generic, portal-wide, "stuff".
 */

export let Shared;

if (typeof window.Shared === 'undefined') {
	Shared = {};
} else {
	Shared = window.Shared;
}

Shared.getBaseUrl = function () {
	// Get the base URL
	const currentURI = new URL(window.location);
	let url = currentURI.protocol;
	url += '//';
	url += currentURI.hostname;

	if (['80', '443'].indexOf(currentURI.port) === -1) {
		url += ':' + currentURI.port;
	}

	// Add the last slash
	url += '/';

	return url;
};

/**
 * Adds a new event listener that will fire an event based on requestAnimationFrame
 *
 * RequestAnimationFrame tells the browser to update an animation
 * (the DOM in many of our use cases) before the next repaint. This means that
 * the resize event will only be called at every animation frame.
 * Very efficient with no timeouts required!
 *
 * This can be used to throttle a variety of events including mouse clicks for
 * drawing!
 *
 * To initialise the event simply do e.g. Shared.throttle("resize", window, fn)
 *
 * @param {String} type	- the type of event to add
 * @param {Object} attachTo - the object onto which to attach the event listener
 * @param {Function} callback - the code to execute after an animation frame
 * @return void
 */

Shared.throttle = function (type, attachTo, callback) {
	attachTo = attachTo || window;
	let running = false;

	const eventCallback = function () {
		if (running) {
			return;
		}

		running = true;

		window.requestAnimationFrame(function () {
			callback();
			running = false;
		});
	};

	attachTo.addEventListener(type, eventCallback);
};

/**
 * Function to convert a float to a string, formatted like the currency set
 * in $currency Includes a (poor) fallback for browsers without proper
 * toLocaleString support (Safari)
 *
 * @param {Number} amount - Number that should be converted to a currency-string
 * @param {string} currency - ISO code for the currency to convert to
 * @param {string} [symbol] - Fallback symbol for safari
 * @param {Number} [decimal=0] - Amount of digits to show behind the decimal point
 * @param {string} [currency_symbol='yes'] - Do we want a currency symbol in the returned string?
 * @returns {string}
 */

Shared.formatAsLocalisedCurrency = function (amount, currency, symbol, decimal = 0, currency_symbol = 'yes') {
	const options = {
		minimumFractionDigits: decimal,
		maximumFractionDigits: decimal
	};

	const options_fallback = {
		decimals: decimal
	};

	if (currency_symbol === 'yes') {
		options.currency = currency;
		options.style = 'currency';
		options_fallback.prefix = symbol + ' ';
	}

	/**
	 * Check if we need to convert the number to a localised value.
	 * If number is not an integer or is above 1000, then we need
	 * to convert it.
	 */

	const convert = parseInt(amount, 10) !== amount || amount >= 1000;
	let newHtml = parseFloat(amount).toLocaleString('nu', options);

	return newHtml;
};

/**
 * All header dropDown instances stored here
 * Used to iterate over each instance and hide them
 * This way can check if instance should be closed or not
 */

Shared.headerDropDownInstances = [];

/**
 * Run code only for a certain breakpoint.
 *
 * This code checks if the specified breakpoint(s) is/are active at the current
 * time and executes the callback provided if at least one match is found.
 * The function will also return true or false depending on if a match is found.
 *
 * To use this function  make sure to bind 'this' to the callback to keep
 * your chosen execution context.
 *
 * You can use the breakpoints:
 * 'Small', 'Medium', 'Large', 'ExtraLarge'
 * If no breakpoint is specified the code will not execute.
 * A warning will be triggered for this.
 *
 * @param {String|Array} breakPoints - a list of breakpoints
 * @param {Function} [callback] - the function to call if the chosen breakpoint is active
 * @return {Boolean} match - whether or not the passed in breakpoints match or not
 */

Shared.breakpoints = function (breakPoints, callback) {
	// Blank array of media query results
	const mediaQueries = [];
	let match = false;

	// For each breakpoint given, check if it is currently active and add to array
	if (Object.prototype.toString.call(breakPoints) === '[object Array]') {
		breakPoints.forEach(function ($breakPoint) {
			mediaQueries.push(isActiveBreakpoint($breakPoint));
		});
	} else {
		mediaQueries.push(isActiveBreakpoint(breakPoints));
	}

	/**
	 * Returns a boolean whether or not the window is currently a given size.
	 *
	 * @param size {String}
	 * @returns {Boolean}
	 */

	function isActiveBreakpoint(size) {
		let mediaMatch = '';

		switch (size) {
			case 'ExtraLarge':
				mediaMatch = 'all and (min-width: 1025px)';
				break;

			case 'Large':
				mediaMatch = 'all and (min-width: 769px) and (max-width: 1024px)';
				break;

			case 'Medium':
				mediaMatch = 'all and (min-width: 481px) and (max-width: 768px)';
				break;

			case 'Small':
				mediaMatch = 'all and (max-width: 480px)';
				break;

			default:
				mediaMatch = 'none';
				console.warn('Shared.breakpoints: invalid breakpoint usage: ' + size);
				break;
		}

		return window.matchMedia(mediaMatch).matches;
	}

	// Looks through the array of media queries to see if one matches.
	// As soon as a match has been found, executes the callback and breaks the loop.
	mediaQueries.some(
		function (queryBool) {
			if (queryBool) {
				if (callback) {
					callback.apply(this);
				}

				match = true;
				return true;
			}
		}.bind(this)
	);

	return match;
};

/**
 * Lazy load a script and append it to the bottom of the body.
 *
 * This can be used from any module in order to load a script when the element
 * desired scrolls into view. Uses the MooTools More Asset type to load
 * javascript on demand in an async fashion.
 *
 * @param {Array} src - the source of the script to inject
 * @param {Element} element - the element that must be in view for the script to be run
 * @param {Function} [callback] - the function to execute once the script has loaded
 * @param {Number} [detectionPadding] - an optional number of padding
 */

Shared.lazyLoadScript = function (src, element, callback, detectionPadding = 400) {
	let loaded = false;
	let injectScript;

	// Immediately execute the injectScript function just in case the asset
	// is already scrolled into the viewport.
	(injectScript = function () {
		//NOSONAR

		/*
		 * Detect if element has passed the boundary of the viewport minus
		 * some extra padding so script can be loaded already when reached
		 */
		if (element.getBoundingClientRect().top > document.body.getBoundingClientRect().height + detectionPadding) {
			return;
		}

		// Remove the event so that the asset is not loaded into the header
		// multiple times
		document.removeEventListener('scroll', injectScript);

		let srcLength = src.length;

		if (src.length === 0) {
			console.warn('Shared.lazyLoadScript: no source to inject!');
			return;
		}

		let loadedSources = 0;

		for (let i = 0; i < srcLength; i++) {
			// Trigger loaded to true.
			loaded = true;
			Async.javascript(src[i], function () {
				loadedSources++;

				if (loadedSources === srcLength) {
					callback ? callback() : null;
				}
			});
		}
	})();

	// If the source has not been loaded yet attach the on scroll event.
	if (!loaded) {
		document.addEventListener('scroll', injectScript);
	}
};

/**
 * Adds a callback function to be executed when pipeline tracking is available
 *
 * If the tracking is already available on the window, immediately execute.
 * If not, attach the callback to fire on the event listener.
 *
 * @param {Function} callback
 */

Shared.addFunctionToPipelineTracker = function (callback) {
	if (typeof window.DataTracker !== 'undefined') {
		callback(window.DataTracker);
	} else {
		document.addEventListener('DataTrackerReady', function () {
			callback(window.DataTracker);
		});
	}
};

/*//VERSION_TAG//*/

Element.implement({
	/**
	 * Rewrites a number so it can be displayed as a localised value.
	 *
	 * <b>Input:</b>
	 * <span class="LocaleNumber" data-decimals="2">10000</span>
	 *
	 * <b>Output:</b>
	 * <span class="LocaleNumber">10,000</span>
	 *
	 * @return {HTMLElement}
	 */

	formatAsNumber: function () {
		const decimals = this.get('data-decimals') || 0;
		const number = this.get('html').toFloat();

		// Check for invalid usage.
		if (isNaN(number)) {
			console.warn('Shared.formatAsNumber: innerHTML of element is not an float.', this);
			return this;
		}

		this.set(
			'html',
			number.toLocaleString('nu', {
				style: 'decimal',
				minimumFractionDigits: decimals,
				maximumFractionDigits: decimals
			})
		);

		return this;
	},

	/**
	 * Rewrites a date or time so it can be displayed as a formatted value.
	 *
	 * <b>Input:</b>
	 * <time datetime="2015-03-31 14:00:00"
	 * 		 data-format="HH:MM"
	 * 		 data-format-title="">2015-03-31 14:00:00</span>
	 *
	 * <b>Output:</b>
	 * <time datetime="2015-03-31 14:00:00"
	 * 		 data-format="HH:MM"
	 * 		 title="Tu Mar 31 14:00:00 2015">16:00</span>
	 *
	 * @return {HTMLElement}
	 */

	formatAsDateTime: function () {
		const dateTime = this.get('datetime').trim().toString();
		if (dateTime === '') {
			console.warn('Shared.formatAsDateTime: No values specified', dateTime);
			return this;
		}

		const dataFormat = this.get('data-format');
		if (!dataFormat) {
			console.warn('Shared.formatAsDateTime: No data-format attribute available', this);
			return this;
		}

		const format = dataFormat.trim().toString();
		if (!format || format === '') {
			console.warn(
				'Shared.formatAsDateTime: No date or time formatting ' +
					'present, make sure that attribute data-format is present and ' +
					'not empty.'
			);
			return this;
		}

		const formatted = moment(dateTime).format(format);

		if (formatted === 'Invalid date') {
			return this;
		}

		this.set('text', formatted);

		const dataFormatTitle = this.get('data-format-title');
		if (dataFormatTitle) {
			this.set('title', moment(dateTime).format(dataFormatTitle));
		}

		return this;
	}
});

/**
 * Adding clone function to the function prototype.
 * @returns {Function}
 *
 * @see http://stackoverflow.com/questions/1833588/javascript-clone-a-function
 */

Function.prototype.clone = function () {
	const that = this;
	const temp = function () {
		return that.apply(this, arguments);
	};
	for (const key in this) {
		if (this.hasOwnProperty(key)) {
			temp[key] = this[key];
		}
	}
	return temp;
};

document.addEventListener('DOMContentLoaded', function () {
	/*
	 * Bootstrap "document.body" for use with IE <= 8 and MooTools.
	 *
	 * In order for Internet Explorer 8 (or lower) to work reliably within our
	 * portals, the below code should be the *first* piece of code added to the
	 * "domready" event!
	 *
	 * Old versions of Internet Explorer do not automatically extend Element (of
	 * which "document.body" is the first and foremost) with all of MooTools'
	 * Element methods. Since all other browsers do support this and the call to
	 * "document.body" is rather fundamental, our existing code-base/practices do
	 * not take this into account. Rather, a compatibility fix is provided here.
	 * If IE8 ever becomes a thing of the past, this code can safely be removed.
	 */

	$(document.body);

	/* a general wrapper that handles all the uses of the mootools OverText
	 * Class.
	 *  - also overules the standard use of the alt or title tag as the
	 *  data source for the overtext displayed to the custom attribute
	 *  data-OverText (conform HTML5 standards).
	 *  to use simply apply the custom attribute data-OverText.
	 */

	(function () {
		const textFieldsWithOvertext = document.querySelectorAll('[data-OverText]');

		if (textFieldsWithOvertext.length > 0) {
			console.warn("Please don't use this anymore, use placeholder attributes.");
			// apply all the overview instances:
			Array.convert(textFieldsWithOvertext).forEach(function (textField) {
				const storedOverText = textField.get('data-overtext');
				new OverText(textField, { textOverride: storedOverText });
				//NOTE: instances of OverText are available as the Global object OverText provided by MooTools
			});
		}
	})();

	document.addEventListener('redirect', function (uri) {
		if (window.location !== uri) {
			window.location = uri;
		}
	});
});

window.Shared = Shared;
