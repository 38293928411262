import { DataStorage } from '@studyportals/data-storage';
import cookieHandler from '../../../../../Templates/Shared2016/TS/CookieHandler';

class SessionExpirationNotification {
	private innerWrapper: HTMLDivElement | null = null;
	private outerWrapper: HTMLDivElement | null = null;
	private exitButton: HTMLButtonElement | null = null;
	private signedOutMessageStorageLabel = 'UniAdmin/AutomaticallySignedOut';
	private signedOutMessageExpectedValue = 'true';

	constructor() {
		this.innerWrapper = document.querySelector('.js-sessionExpirationNotificationInnerWrapper');
		this.outerWrapper = document.querySelector('.js-sessionExpirationNotificationOuterWrapper');
		this.exitButton = document.querySelector('.js-sessionExpirationNotificationExitButton');

		if (!this.innerWrapper || !this.exitButton) {
			return;
		}

		if (!parseInt(this.innerWrapper.dataset.isLoggedIn as string)) {
			this.showSignedOutNotificationIfNecessary();
			return;
		}

		this.prepareForNotificationsToBeShown();
	}

	private showSignedOutNotificationIfNecessary(): void {
		const signedOutNotification = document.querySelector('.js-sessionExpirationSignedOutNotification');
		const signedOut = DataStorage.retrieve(this.signedOutMessageStorageLabel) as string | null;
		if (!signedOutNotification || signedOut !== this.signedOutMessageExpectedValue) {
			return;
		}

		signedOutNotification.classList.remove('Hidden');
		DataStorage.remove(this.signedOutMessageStorageLabel);
	}

	private prepareForNotificationsToBeShown(): void {
		this.addEventListeners();

		const millisecondsLeft = this.retrieveMillisecondsUntilExpiration();
		const timeUntilLastNotification = millisecondsLeft - 1000 * 60 * 30;
		const timeUntilFirstNotification = millisecondsLeft - 1000 * 60 * 60;

		this.afterGivenTimeShowNotification(timeUntilLastNotification, '30 minutes');
		this.afterGivenTimeShowNotification(timeUntilFirstNotification, '1 hour');
		this.afterGivenTimeLogOut(millisecondsLeft);
	}

	private addEventListeners(): void {
		this.exitButton?.addEventListener('click', this.onExitButtonClick.bind(this));
	}

	private onExitButtonClick(): void {
		this.innerWrapper?.classList.add('Hidden');
		if (!this.outerWrapper) {
			return;
		}

		this.outerWrapper.dataset.wrapperVisible = 'false';
	}

	private afterGivenTimeShowNotification(milliseconds: number, durationAsText: string): void {
		if (milliseconds <= 0) {
			return;
		}

		setTimeout(() => {
			const durationElement = document.querySelector('.js-sessionExpirationNotificationDuration');
			if (!durationElement) {
				return;
			}

			durationElement.innerHTML = durationAsText;

			this.innerWrapper?.classList.remove('Hidden');
			if (!this.outerWrapper) {
				return;
			}

			this.outerWrapper.dataset.wrapperVisible = 'true';
		}, milliseconds);
	}

	private afterGivenTimeLogOut(milliseconds: number): void {
		if (milliseconds <= 0) {
			return;
		}

		setTimeout(() => {
			DataStorage.store(this.signedOutMessageStorageLabel, this.signedOutMessageExpectedValue);
			// Use the origin and pathname to prevent a potential hash from messing with the redirect.
			window.location.href = `${window.location.origin}${window.location.pathname}?logout=true`;
		}, milliseconds);
	}

	private retrieveMillisecondsUntilExpiration(): number {
		const sessionCookie = cookieHandler.retrieveSessionExpirationTimeCookie();
		return parseInt(sessionCookie) * 1000 - Date.now();
	}
}

window.addEventListener('DOMContentLoaded', () => {
	new SessionExpirationNotification();
});
