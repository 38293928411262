/**
 * @file DatePicker.js
 *
 * For all specified input fields with input[type=date] show what the inserted
 * value looks like as a date in a textual way behind the element.
 *
 * @author Stefan Klokgieters <stefan@studyportals.com>
 * @version 1.2.0
 * @copyright © 2016 StudyPortals B.V., all rights reserved.
 */

Element.implement({
	/**
	 * Check if the input field contains a valid date and show a preview of the
	 * formatted value behind the input field as a rendered date.
	 *
	 * Default formatting is: 'DD MMM YYYY (ddd)'
	 *
	 * <b>Input:</b>
	 * <input type="date" name="Example" value="0" data-format="MMMM YYYY">
	 *
	 * @return {HTMLElement}
	 */

	datePicker: function () {
		// Check if type is date.
		if (this.getAttribute('type') !== 'date') {
			console.warn('DatePicker.js: element is no input field with type date', this);
			return this;
		}

		/*
		 Since dates can be inserted in multiple ways it's good to set a
		 placeholder describing the preferred value format.
		 */
		if (!this.hasAttribute('placeholder')) {
			console.warn('DatePicker.js: no placeholder found describing the preferred value format, creating one...', this);
			this.setAttribute('placeholder', 'yyyy-mm-dd');
		}

		// Create a span element and inject it at the bottom of 'this' which is your datepicker element.
		var $span = this.getNext('.DateExample');
		if (!$span) {
			$span = new Element('span.DateExample');
			this.grab($span, 'after');
		}

		// If there is no value mark the field as invalid.
		if (!this.value) {
			this.classList.remove('Error');
			$span.set('html', '');
			return this;
		}

		// Check if it the date is valid.
		var $date = new Date(this.value);
		if (isNaN($date)) {
			this.classList.add('Error');
		} else {
			this.classList.remove('Error');
		}

		// Default formatting.
		var $format = 'DD MMM YYYY (ddd)';

		// When a data-format attribute is present and is not empty use the custom formatting.
		if (this.hasAttribute('data-format') === true && this.getAttribute('data-format') !== '') {
			$format = this.getAttribute('data-format');
		}

		// Show the user the actual date.
		$span.set('html', moment($date).format($format));
	}
});
