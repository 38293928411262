/**
 * @file Login.js
 *
 * @author Danko Adamczyk <danko@studyportals.com>
 * @copyright © 2016 StudyPortals B.V., all rights reserved.
 * @version 1.0.0
 */

const Ladda = require('ladda/js/ladda');
import rollbarLogger from '../../../../../../Templates/Shared2016/TS/RollbarLogger';

window.addEvent('domready', function () {
	var redirectErrorCookie = function () {
		var $ErrorCookie = JSON.decode(Cookie.read('Error'));

		if ($ErrorCookie && $ErrorCookie.status === 403) {
			console.warn('Deleting cookie');
			Cookie.dispose('Error');
			window.location = $ErrorCookie['url'];
			return true;
		}

		return false;
	};

	/**
	 * Login module.
	 *
	 * <p>Provides functionality to login you in and/or out from the portal.</p>
	 */

	var Login = {
		Hook: null,

		init: function () {
			this.Hook = $('Login');
			if (!this.Hook) {
				console.warn('Endor.Auth.Login', 'Missing Element with id Login');
				return;
			}

			window.addEventListener('load', () => {
				if (this._shouldRedirectToReport()) {
					this._writeRedirectToReportCookie();
				}
			});

			if (this._redirectBackToErrorPage()) {
				// There is nothing else to do if we are redirecting the user.
				return;
			}

			var $Btn = this.Hook.getElement('.ladda-button');
			if ($Btn) {
				Ladda.bind($Btn);
			}

			this.Hook.addEvent('submit:relay(form[name="Login"])', this.callbacks.loginFormSubmit);
			this.Hook.addEvent('click:relay(a[href="?logout=true"])', this.callbacks.logoutLinkClick);
		},

		_getUrlQueryParameters() {
			var url = new URL(window.location.href);
			var searchParams = url.searchParams;

			return searchParams;
		},

		_shouldRedirectToReport() {
			if (window.location.search === '') {
				return false;
			}

			var searchParams = this._getUrlQueryParameters();

			return 'new' === searchParams.get('report');
		},

		_writeRedirectToReportCookie() {
			const searchParams = this._getUrlQueryParameters();
			const campaignParameter = searchParams.get('campaign');
			const currentTime = new Date();
			const reportURL = window.CommonsModule.getReportingPortalBaseUrl() + 'reporting/' + campaignParameter;

			const cookieValue = { status: 403, time: currentTime, url: reportURL };

			Cookie.write('Error', JSON.encode(cookieValue));
		},

		/**
		 * Do we need to redirect back to the error page?
		 *
		 * <p>This should only happen in case of an unauthorized exception (403).</p>
		 *
		 * @return {boolean}
		 * @private
		 */
		_redirectBackToErrorPage: function () {
			if (this.Hook.getProperty('data-logged_in') !== 'true') {
				return false;
			}

			return redirectErrorCookie();
		},

		callbacks: {
			/**
			 * Handle the login form submit.
			 *
			 * <p>This will log you in at the Auth driver. When that succeeds it
			 * fills the token field and triggers a form submit to also log you
			 * in at this portal.</p>
			 *
			 * Note: the password field is disabled before submit to prevent
			 * sending the plain password over http.
			 * Note2: The thing in Note1 already happens towards the Auth driver
			 * so why bother..?
			 *
			 * @param event
			 */
			loginFormSubmit: function (event) {
				event.preventDefault();

				var $Form = this;

				const unhideElement = (elementId) => {
					const element = document.getElementById(elementId);

					if (element && element.classList.contains('Hidden')) {
						element.classList.remove('Hidden');
						return element;
					}
				};

				const hideErrorMessages = () => {
					const elements = document.getElementsByClassName('ErrorMessage');

					for (let i = 0; i < elements.length; i++) {
						elements[i].classList.add('Hidden');
					}
				};

				hideErrorMessages();

				var loginFailure = function () {
					Ladda.stopAll();

					const invalidSessionSpan = document.getElementById('invalid-session');

					if (invalidSessionSpan && invalidSessionSpan.classList.contains('Hidden')) {
						$('invalid-credentials').removeClass('Hidden');
					}
				};

				const handleUserSessionAndRedirect = async (authenticateResult) => {
					await window.CommonsModule.singleSignOnSessionManager.ifAuthenticationSucceededSetupUserSessionAndKeepAlive(
						authenticateResult
					);
					const wasRedirectedOnErrorCookie = redirectErrorCookie();

					if (!wasRedirectedOnErrorCookie) {
						window.CommonsModule.redirectToLandingPage();
					}
				};

				const authenticateAfterRegistrationAndRedirect = async (username, password) => {
					const authenticateResult = await window.Mfa.authenticate(username, password);

					if (authenticateResult.error) {
						throw new Error(authenticateResult.error);
					}

					await handleUserSessionAndRedirect(authenticateResult);
				};

				const handleAuthenticationErrors = async (authenticateResult, username, password) => {
					Ladda.stopAll();

					const mfaErrorCodes = window.Mfa.errorCodes;

					switch (authenticateResult.error) {
						case mfaErrorCodes.USER_DOES_NOT_EXIST: {
							const signUpResult = await window.Mfa.signUp(username, password);

							if (signUpResult.userConfirmed) {
								await authenticateAfterRegistrationAndRedirect(username, password);
							} else {
								throw new Error(`Something went wrong with sign-up: ${signUpResult}`);
							}

							break;
						}
						case mfaErrorCodes.INCORRECT_USERNAME_PASSWORD: {
							unhideElement('invalid-username-password');
							break;
						}
						case mfaErrorCodes.MFA_CODE_REQUIRED: {
							if(unhideElement('MfaCode')) {
								document.getElementById('LoginButton').innerText = 'Continue';
							}
							break;
						}
						case mfaErrorCodes.INVALID_MFA_CODE: {
							unhideElement('invalid-mfa');
							break;
						}
						default: {
							throw new Error(`Unhandled error: ${authenticateResult.error}`);
						}
					}
				};

				Endor.User.login($Form.username.value, $Form.password.value, {
					async onSuccess() {
						//
						// Get the data of the currently logged in user.
						//
						// The e-mails are changed on all stages but production.
						// Use the e-mail found in the session.
						//
						var userData = Endor.User.getMe();

						if (Endor.User.isFormerEmployee()) {
							loginFailure();
							return;
						}

						let password = $Form.password.value;
						//
						// The passwords are disabled and replaced with 'test' in all
						// environments but production.
						//
						// Cognito's password requirements cannot be set to anything lower
						// than six characters. Therefore, when the password is 'test', additional
						// padding characters are added to meet the requirements.
						if ('test' === password) {
							password += '1234';
						}

						const Mfa = window.Mfa;

						if (!Mfa) {
							throw new Error('Mfa class not available');
						}

						const username = userData.email;

						try {
							const mfaCodeInput = document.getElementById('MfaCode');
							const mfaCode = mfaCodeInput.value ? mfaCodeInput.value : '';

							const authenticateResult = await window.Mfa.authenticate(username, password, mfaCode);

							if (authenticateResult.error) {
								await handleAuthenticationErrors(authenticateResult, username, password);
							} else {
								await handleUserSessionAndRedirect(authenticateResult);
							}

						} catch (e) {
							const errorMessage = `Exception occurred during authentication of user: ${e.message}`;
							rollbarLogger.logCritical(errorMessage, e);

							unhideElement('technical-issues');
							Ladda.stopAll();

							throw new Error(errorMessage);
						}
					},
					onFailure: loginFailure
				});
			},

			/**
			 * Handle the logout link click.
			 *
			 * <p>First logout ath the Auth driver and then redirect the links
			 * url (which should be ?logout=true). This will log you out on this
			 * portal as well.</p>
			 *
			 * @param event
			 */
			logoutLinkClick: function (event) {
				event.preventDefault();

				Cookie.dispose('Error');
				Endor.User.logout();
			}
		}
	};

	Login.init();
});
