import { SingleSignOnSessionManager } from './SingleSignOnSessionManager.js';
import { Shared } from './../../../../../Templates/Shared/JS/Shared.js';

('use strict');

export class CommonsModule {
	constructor() {
		this.Hook = document.getElementById('CommonsModule');
		this.instanceId = this.Hook.dataset.instanceId;
		this.cookieDomain = this.Hook.dataset.hsSsoCookieDomain;

		this.stage = null;
		this.singleSignOnSessionManager = new SingleSignOnSessionManager(this.instanceId, this.cookieDomain);
	}

	createSingleSignOnSession(email, password) {
		return this.singleSignOnSessionManager.loginOrSignUpAndSetupSessionAndKeepAlive(email, password).catch((e) => {
			const errorParts = [
				'Could not create the SSO session. Unless a valid session already exists,',
				'the user will not have access to the off site projects.'
			];
			console.error(errorParts.join(' '), e);
		});
	}

	redirectToLandingPage() {
		window.location.href = Shared.getBaseUrl() + 'organisation/';
	}

	createSingleSignOnSessionAndRedirectToLandingPage(email, password) {
		this.createSingleSignOnSession(email, password).then(() => this.redirectToLandingPage());
	}

	/**
	 * Gets the current deployment stage.
	 */
	getStage() {
		if (!this.stage) {
			const url = window.location.href;

			if (-1 != url.indexOf('uniadmin.prtl.fyi')) {
				this.stage = 'tst';
			} else {
				const leftToken = 'administration.';
				const leftTokenIndex = url.indexOf(leftToken);
				const rightToken = 'studyportals';
				const rightTokenIndex = url.indexOf(rightToken);

				const stageSubstring = url.substring(leftTokenIndex + leftToken.length, rightTokenIndex);

				if (0 === stageSubstring.length) {
					this.stage = 'prd';
				} else {
					this.stage = stageSubstring.substr(0, stageSubstring.length - 1);
				}
			}
		}

		return this.stage;
	}

	/**
	 * Registers a new tacking event.
	 *
	 * @param {string} 	team 		The team to whom the product that generated the event belongs.
	 * @param {string} 	product		The product that generated the event.
	 * @param {string}	category 	The event's category.
	 * @param {string} 	url			The URL where the event was generated.
	 * @param {array} 	tags		The tags that are to be associated with the event.
	 * @param {string} 	eventAction The event's action.
	 * @param {string} 	eventLabel	The event's label.
	 * @param {*}		eventData 	The event's data.
	 * @param {number}  timeElapsed An optional field used to track time information.
	 */
	trackEvent(team, product, category, url, tags, eventAction, eventLabel, eventData, timeElapsed) {
		eventData = eventData || '';
		const data = JSON.stringify(eventData);

		const tracker = new SPTracker({
			environment: this.getStage(),
			team: team,
			product: product,
			category: category,
			url: url,
			tags: tags
		});

		return new Promise((resolve) => {
			const eventParams = {
				action: eventAction,
				label: eventLabel,
				tags: [],
				eventData: data
			};

			if (timeElapsed) {
				eventParams['timeElapsed'] = timeElapsed;
			}

			tracker
				.track(eventParams, true)
				.then(() => resolve())
				.catch((e) => console.error(e));
		});
	}

	/**
	 * Registers a new tacking event that belongs to the House Stark team.
	 *
	 * @param {string} 	product		The product that generated the event.
	 * @param {string}	category 	The event's category.
	 * @param {string} 	url			The URL where the event was generated.
	 * @param {array} 	tags		The tags that are to be associated with the event.
	 * @param {string} 	eventAction The event's action.
	 * @param {string} 	eventLabel	The event's label.
	 * @param {*}		eventData 	The event's data.
	 * @param {number}  timeElapsed An optional field used to track time information.
	 */
	trackEventForHouseStark(product, category, url, tags, eventAction, eventLabel, eventData, timeElapsed) {
		return this.trackEvent('hs', product, category, url, tags, eventAction, eventLabel, eventData, timeElapsed);
	}

	/**
	 * Registers a new tacking event that belongs to the House Stark team and is
	 * generated by the current page.
	 *
	 * @param {string} 	product		The product that generated the event.
	 * @param {string}	category 	The event's category.
	 * @param {array} 	tags		The tags that are to be associated with the event.
	 * @param {string} 	eventAction The event's action.
	 * @param {string} 	eventLabel	The event's label.
	 * @param {*}		eventData 	The event's data.
	 * @param {number}  timeElapsed An optional field used to track time information.
	 */
	trackEventForHouseStarkOnCurrentPage(product, category, tags, eventAction, eventLabel, eventData, timeElapsed) {
		return this.trackEventForHouseStark(
			product,
			category,
			window.location.href,
			tags,
			eventAction,
			eventLabel,
			eventData,
			timeElapsed
		);
	}

	/**
	 * Registers a new tacking event that belongs to the Addams Family team.
	 *
	 * @param {string} 	product		The product that generated the event.
	 * @param {string}	category 	The event's category.
	 * @param {string} 	url			The URL where the event was generated.
	 * @param {array} 	tags		The tags that are to be associated with the event.
	 * @param {string} 	eventAction The event's action.
	 * @param {string} 	eventLabel	The event's label.
	 * @param {*}		eventData 	The event's data.
	 * @param {number}  timeElapsed An optional field used to track time information.
	 */
	trackEventForAddamsFamily(product, category, url, tags, eventAction, eventLabel, eventData, timeElapsed) {
		return this.trackEvent('af', product, category, url, tags, eventAction, eventLabel, eventData, timeElapsed);
	}

	/**
	 * Registers a new tacking event that belongs to the Addams Family team and is
	 * generated by the current page.
	 *
	 * @param {string} 	product		The product that generated the event.
	 * @param {string}	category 	The event's category.
	 * @param {array} 	tags		The tags that are to be associated with the event.
	 * @param {string} 	eventAction The event's action.
	 * @param {string} 	eventLabel	The event's label.
	 * @param {*}		eventData 	The event's data.
	 * @param {number}  timeElapsed An optional field used to track time information.
	 */
	trackEventForAddamsFamilyOnCurrentPage(product, category, tags, eventAction, eventLabel, eventData, timeElapsed) {
		return this.trackEventForAddamsFamily(
			product,
			category,
			window.location.href,
			tags,
			eventAction,
			eventLabel,
			eventData,
			timeElapsed
		);
	}
}
(function () {
	window.addEventListener('DOMContentLoaded', () => (window.CommonsModule = new CommonsModule()));
	window.addEventListener('load', () => {
		window.CommonsModule.singleSignOnSessionManager.keepUserSessionAliveIfSetup();
	});
})();
