import { LoginErrorMessages, UserCampaignManagementAPIClient } from '@studyportals/campaign-management-api-interface';

export class Mfa {
	public readonly errorCodes = LoginErrorMessages;
	private userCampaignManagementClient = new UserCampaignManagementAPIClient(
		window.Configuration.campaignManagementAPI
	);

	public async authenticate(username: string, password: string, mfaCode = ''): Promise<any> {
		const authenticationData = await this.userCampaignManagementClient.authenticateUser(username, password, mfaCode);
		const successfulAuthenticationResult = {
			__i_successful_authentication_result__: true,
			error: authenticationData.error,
			userIdentifier: username,
			idToken: authenticationData.idToken,
			refreshToken: authenticationData.refreshToken,
			accessToken: authenticationData.accessToken,
			idTokenAvailabilityInMs: 3600000, // 1 hour in ms
			idTokenTimeOfCreation: new Date()
		};

		if (authenticationData.error !== undefined) {
			successfulAuthenticationResult.__i_successful_authentication_result__ = false;
			successfulAuthenticationResult.error = authenticationData.error;

			return successfulAuthenticationResult;
		}

		return successfulAuthenticationResult;
	}

	public async signUp(username: string, password: string): Promise<any> {
		const signUpResult = await this.userCampaignManagementClient.signUpUser(username, password);

		if (signUpResult.error) {
			return { error: signUpResult.error };
		}

		return signUpResult;
	}
}

document.addEventListener('DOMContentLoaded', () => {
	const mfa = new Mfa();

	window.Mfa = mfa;
});
