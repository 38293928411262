/**
 * @file Storage.js
 * Create a browser independent storage solution.
 *
 * @copyright StudyPortals B.V. 2015, all rights reserved.
 * @version 2.0.0
 */

export let DataStorage = {
	enabled: null,

	/**
	 * Check if the localStorage is available.
	 *
	 * This function checks if the localStorage is available and sets the
	 * enabled parameter to true if it is available, or false when it's not.
	 *
	 * @return void
	 */

	init() {
		if (typeof localStorage !== 'undefined') {
			try {
				/**
				 * Test if we are able to set and remove values.
				 *
				 * In some browsers (shame on you Safari!) the localStorage is
				 * available, but under certain conditions (like inPrivate, shame on
				 * you Safari) errors are thrown as soon as you are trying to add
				 * something to the localStorage (again, shame on you Safari!).
				 * These calls will trigger exceptions in that case.
				 */

				localStorage.setItem('test', 'dummy value');
				localStorage.removeItem('test');

				this.enabled = true;
			} catch (error) {
				this.enabled = false;
			}
		}
	},

	/**
	 * Store a value into localStorage if available, otherwise store in cookie.
	 *
	 * @param {String} $key - LocalStorage key (name).
	 * @param {String} $value - LocalStorage key values.
	 * @param {Number} [$ttl] - LocalStorage custom expiry date ins seconds.
	 *
	 * @returns {boolean}
	 */

	store($key, $value, $ttl) {
		if (isNaN($ttl)) {
			// Expiry date of 30 minutes by default.
			$ttl = 1800;
		}

		if (!this.enabled) {
			document.cookie = `${$key}=${$value};expires=${$ttl / 86400}`;
		} else {
			// Manually insert an expiry date
			let $time = new Date();
			$time = $time.setSeconds($time.getSeconds() + $ttl);

			let $container = {
				expires: $time,
				data: $value
			};

			localStorage[$key] = JSON.stringify($container);
		}

		return true;
	},

	/**
	 * Retrieve a value from localStorage if available, otherwise get from cookie.
	 *
	 * @param {String} $key
	 * @return {String}
	 */

	retrieve($key) {
		// Get the required cookie.
		function getCookie(name) {
			let pattern = RegExp(name + '=.[^;]*');
			let matched = document.cookie.match(pattern);

			if (matched) {
				let cookie = matched[0].split('=');
				return cookie[1];
			}

			return '';
		}

		// If there is no localStorage support use cookies as a fallback.
		if (!this.enabled) {
			return getCookie($key);
		} else {
			let $decoded = localStorage[$key];
			try {
				$decoded = JSON.parse($decoded);
			} catch (error) {
				$decoded = {};
			}

			if (!$decoded || !$decoded.hasOwnProperty('expires') || !$decoded.hasOwnProperty('data')) {
				return '';
			}

			// Check if the data is expired
			if (new Date() > Date.parse($decoded.expires)) {
				localStorage[$key] = null;
				return '';
			}

			return $decoded.data;
		}
	},

	/**
	 * Remove a key from the data source.
	 *
	 * @param {String} $key
	 * @return void
	 */

	remove($key) {
		// Clear the specific localStorage or cookie entry.
		if (!this.enabled) {
			document.cookie = `${$key}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
		} else {
			localStorage[$key] = null;
		}
	},

	/**
	 * Updates an object in local storage
	 * or creates the object if it does not exist
	 *
	 * @param {String} $key
	 * @param {Object} $value
	 * @param {Number} $ttl
	 * @return {*|String}
	 */
	update($key, $value, $ttl = null) {
		let $old = this.retrieve($key);
		if (typeof $old !== 'object') {
			$old = {};
		}
		$old = Object.assign({}, $old, $value);
		this.store($key, $old, $ttl);

		return $old;
	}
};

DataStorage.init();
