/* global AWSCognito */
import { Shared } from './../../../../../Templates/Shared/JS/Shared.js';

export class SingleSignOnSessionManager {
	constructor(moduleInstanceId, cookieDomain) {
		this.moduleInstanceId = moduleInstanceId;
		this.cookieDomain = cookieDomain;

		this.userPoolId = '';
		this.clientId = '';
		this.isInitialized = false;
		this.promiseToInitialize = null;

		this.userSessionManager = null;
		this.authenticationServicesProvider = null;
		this.signedRequestsFactory = null;
	}

	initializeAsync() {
		const promiseToGetTheUserPoolData = this.getUserPoolDataAsync();

		const promiseToInitialize = promiseToGetTheUserPoolData
			.then(this.applyUserPoolData.bind(this))
			.then(this.initializeUserSessionManagerUsingUserPoolData.bind(this))
			.then(this.initializeSignedRequestsFactory.bind(this))
			.then(this.initializeAuthenticationServicesProviderUsingUserPoolData.bind(this))
			.then(this.markInstanceAsInitialized.bind(this));

		this.markInstanceAsUndergoingInitialization(promiseToInitialize);

		return promiseToInitialize;
	}

	applyUserPoolData(userPoolData) {
		this.userPoolData = userPoolData.poolId;
		this.clientId = userPoolData.clientId;

		return userPoolData;
	}

	initializeUserSessionManagerUsingUserPoolData(userPoolData) {
		this.userSessionManager = new UserSessionCookieManager(
			userPoolData.clientId,
			userPoolData.poolId,
			this.cookieDomain
		);

		return userPoolData;
	}

	initializeSignedRequestsFactory(userPoolData) {
		this.signedRequestsFactory = new SignedSuperAgentRequestFactory(this.userSessionManager);

		return userPoolData;
	}

	initializeAuthenticationServicesProviderUsingUserPoolData(userPoolData) {
		this.authenticationServicesProvider = new CognitoAuthenticationServicesProvider(
			userPoolData.poolId,
			userPoolData.clientId
		);

		return userPoolData;
	}

	markInstanceAsInitialized() {
		this.isInitialized = true;
		this.promiseToInitialize = null;
	}

	markInstanceAsUndergoingInitialization(intializationPromise) {
		this.promiseToInitialize = intializationPromise;
	}

	initializeIfNeededAsync() {
		if (this.isInstanceUndergoingInitialization()) {
			return this.promiseToInitialize;
		}

		if (this.isInitialized) {
			return Promise.resolve();
		}

		return this.initializeAsync();
	}

	isInstanceUndergoingInitialization() {
		return null != this.promiseToInitialize;
	}

	loginOrSignUpAndSetupSessionAndKeepAlive(email, pwd) {
		const promiseToInitialize = this.initializeIfNeededAsync();

		const promiseToLoginOrSignUpAndCreateSession = promiseToInitialize
			.then(() => this.authenticate(email, pwd))
			.then((authenticationResult) =>
				this.ifUserDoesNotExistCreateAccountAndAuthenticate(authenticationResult, email, pwd)
			)
			.then((authenticationResult) => this.ifAuthenticationSucceededSetupUserSessionAndKeepAlive(authenticationResult));

		return promiseToLoginOrSignUpAndCreateSession;
	}

	keepUserSessionAliveIfSetup() {
		const promiseToInitialize = this.initializeIfNeededAsync();

		const promiseToKeepTheUserSessionAlive = promiseToInitialize.then(() => this.userSessionManager.keepAliveIfSetup());

		return promiseToKeepTheUserSessionAlive;
	}

	authenticate(email, pwd) {
		return this.authenticationServicesProvider.authenticate(email, pwd);
	}

	authenticationSucceeded(authenticationResult) {
		return true === authenticationResult.__i_successful_authentication_result__;
	}

	authenticationFailedBecauseTheUserDoesNotExist(authenticationResult) {
		return (
			true === authenticationResult.__i_failed_authentication_result__ &&
			AuthenticationFailureReason.USER_DOES_NOT_EXIST === authenticationResult.reason
		);
	}

	ifUserDoesNotExistCreateAccountAndAuthenticate(authenticationResult, email, pwd) {
		if (this.authenticationFailedBecauseTheUserDoesNotExist(authenticationResult)) {
			const promiseToRegisterAndLogin = this.authenticationServicesProvider
				.registerUser(email, pwd)
				.then(() => this.authenticate(email, pwd))
				.then((authenticationResult) => this.ifAuthenticationSucceededAddUserToGroups(authenticationResult));

			return promiseToRegisterAndLogin;
		}

		return authenticationResult;
	}

	ifAuthenticationSucceededSetupUserSessionAndKeepAlive(authenticationResult) {
		if (this.authenticationSucceeded(authenticationResult)) {
			this.userSessionManager.setupFromSuccessfulAuthenticationAndKeepAlive(authenticationResult);
		}

		return authenticationResult;
	}

	ifAuthenticationSucceededAddUserToGroups(authenticationResult) {
		return new Promise((resolve, reject) => {
			const requestToAddTheUserToGroups = new Request({
				url: Shared.getBaseUrl() + 'Ajax.php',
				method: 'get',
				data: {
					action: `addUserToGroups`,
					instance_id: this.moduleInstanceId
				},
				onSuccess: () => resolve(authenticationResult),
				onFailure: (e) => {
					console.log('Could not add the user to the appropriate groups.');
					console.log(e);
				}
			});

			requestToAddTheUserToGroups.queue(3);
		});
	}

	getUserPoolDataAsync() {
		return new Promise((resolve, reject) => {
			if (null != this.userPoolData) {
				resolve(this.userPoolData);
			} else {
				const requestToFetchTheUserPoolData = new Request({
					url: Shared.getBaseUrl() + 'Ajax.php',
					method: 'get',
					data: {
						action: 'getPoolData',
						instance_id: this.moduleInstanceId
					},
					onSuccess: (userPoolData) => {
						resolve(JSON.decode(userPoolData));
					},
					onFailure: () => {
						reject((this.userPoolData = new Error("Could not fetch the user pool's data")));
					}
				});

				requestToFetchTheUserPoolData.queue(3);
			}
		});
	}
}

//
// There is some inconsistency in the namespacing on Amazon's behalf.
//
// The JS classes are found in the AWSCognito.CognitoIdentityServiceProvider namespace,
// while the TypeScript type definitions, as of now, are declared in the module's root.
//
// There is no sane way to get the namespacing done in TypeScript, and using WebPack to load
// the dependencies is out of question in UniAdmin, as of now.
//
// In order for user session manager to be able to refresh the session, the namespaces will
// be hacked in global objects.
//

AWSCognito.CognitoUserPool = AWSCognito.CognitoIdentityServiceProvider.CognitoUserPool;
AWSCognito.CognitoUser = AWSCognito.CognitoIdentityServiceProvider.CognitoUser;
AWSCognito.CognitoRefreshToken = AWSCognito.CognitoIdentityServiceProvider.CognitoRefreshToken;
AWSCognito.AuthenticationDetails = AWSCognito.CognitoIdentityServiceProvider.AuthenticationDetails;
AWSCognito.CognitoUserSession = AWSCognito.CognitoIdentityServiceProvider.CognitoUserSession;
