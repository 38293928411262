/**
 * @file Endor.js
 *
 * @author Danko Adamczyk <danko@studyportals.com>
 * @version 1.1.0
 * @copyright © 2016 StudyPortals B.V., all rights reserved.
 */

(function (window) {
	'use strict';

	window.Endor = window.Endor || {};

	/**
	 * Order the entities based on item_order.
	 *
	 * <p>By default the entities are added with their id as key. Therefore
	 * the list of object is sorted by id. This helper will iterate over
	 * them and sort the list by item_order. The key (id) got an underscore
	 * as suffix to prevent JS from re-ordering again on integers.</p>
	 *
	 * <p>The options in your SLC request should have this setting to work:
	 * <pre>extra.include_order = true</pre></p>
	 *
	 * @param {[Object]} $Entities
	 * @param {string} [$prefix=_] - Used to prevent numerical sorting.
	 *
	 * @returns {[Object]}
	 */
	window.Endor.orderEntities = function ($Entities, $prefix) {
		$prefix = $prefix || '_';

		var $Array = [];

		Object.values($Entities).each(function (Entity) {
			/**
			 * @param {Object} Entity
			 * @param {Number} Entity.item_order
			 */

			$Array[Entity.item_order] = Entity;
		});

		$Entities = {};

		$Array.each(function (Entity) {
			$Entities[$prefix + Entity.id] = Entity;
		});

		return $Entities;
	};

	/**
	 * Generate a unique ID.
	 * There is a new GUID creator in the Endor.Helpers file, please use that one instead.
	 *
	 * @deprecated
	 * @returns {string}
	 */
	window.Endor.guid = function () {
		function s4() {
			return Math.floor((1 + Math.random()) * 0x10000)
				.toString(16)
				.substring(1);
		}

		return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
	};

	window.Endor.Obj = {
		/**
		 * Object diff
		 *
		 * <p>Returns an object containing all the properties from Object_compare that
		 * are not equal in Object_base.</p>
		 *
		 * @param {Object} Object_compare - Object_dirty
		 * @param {Object} Object_base - Object_saved
		 * @return {Object}
		 */

		diff: function (Object_compare, Object_base) {
			let $Diff = {};

			/**
			 * Are these arguments equal?
			 *
			 * @param compare
			 * @param base
			 * @return {boolean}
			 * @private
			 */
			function _isEqual(compare, base) {
				// This works also for arrays.
				if (typeof compare === 'object' && typeof base === 'object') {
					// when both are arrays, elements may be messed.
					// So, do sort before compare
					if (compare.constructor === Array && base.constructor === Array) {
						compare = compare.sort();
						base = base.sort();
					}

					// Do another compare
					const $_Diff = Endor.Obj.diff(compare, base);

					// It is equal when there is no diff found.
					return Object.getLength($_Diff) === 0;
				}

				if (compare === '' && typeof base === 'object' && Object.keys(base).length === 0) {
					// Consider an empty string the same as an empty array or object.
					return true;
				}

				// SL object vs form multiple elements. eg. ['density'] and 'density'
				// eg2. ["listed", "hide_banners", "markup", ...] vs 'listed, hide_banners, markup'
				if (compare !== '' && base.constructor === Array) {
					if (compare.split(',').sort().join() === base.sort().join()) {
						return true;
					}
				}

				if (typeof compare !== typeof base) {
					// Different type.
					return false;
				}

				return compare === base;
			}

			for (let prop in Object_compare) {
				if (Object_compare.hasOwnProperty(prop)) {
					// eslint-disable-line

					const new_value = Object_compare[prop];

					if (Object_base.hasOwnProperty(prop)) {
						// eslint-disable-line

						const old_value = Object_base[prop];

						if (!_isEqual(new_value, old_value)) {
							$Diff[prop] = new_value;
						}
					} else {
						$Diff[prop] = new_value;
					}
				}
			}

			return $Diff;
		}
	};

	Element.implement({
		/**
		 * Check if this form is valid by faking a submit.
		 *
		 * @returns {Element}
		 */

		submitWithHtml5FormValidation: function () {
			/**
			 * prevent the normal submit.
			 *
			 * <p>This method is needed so we can remove this from the submit event
			 * after we are done.</p>
			 * @param event
			 */
			var preventNormalSubmit = function (event) {
				event.preventDefault();
			};

			this.addEvent('submit', preventNormalSubmit);

			// Hack to trigger the HTML5 form validation.

			var selector = 'button[type="submit"]';

			var $Btn = this.getElement(selector);

			if (!$Btn) {
				$Btn = new Element(selector)
					.set('name', 'submit')
					.addClass('Hidden')
					.setProperty('data-html5FormValidation', 'true')
					.inject(this, 'bottom');
			}

			//Ensure all required fields have proper visibility
			var list = [];
			const requiredFields = document.querySelectorAll('[required]');
			requiredFields.forEach((element) => {
				if (element.style.display == 'none') {
					list.push(element);
					element.style.display = '';
				}
			});

			$Btn.click();

			this.removeEvent('submit', preventNormalSubmit);
			list.forEach((element) => {
				element.style.display = 'none';
			});

			return this;
		}
	});

	String.prototype.ucfirst = function () {
		return this.charAt(0).toUpperCase() + this.substr(1);
	};
})(window);
