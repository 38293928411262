import { AuthenticationLayer } from './AuthenticationLayer.js';

('use strict');

/**
 * User object.
 *
 * @constructor
 * @extends AuthenticationLayer
 */
export class Visitor extends AuthenticationLayer {}

(function (_w) {
	_w.Visitor = new Visitor();
})(window);
