class HeaderModule {
	constructor() {
		this.highlightActiveListItem();
	}

	private highlightActiveListItem(): void {
		const location_url = window.location;
		const listItems = document.querySelectorAll('.js-headerModule li');

		// Go backwards through the list, so that the home page (least specific) is left as the last option.
		for (let i = listItems.length - 1; i >= 0; i--) {
			const link = listItems[i].querySelector('a');
			if (location_url.href.indexOf(link?.href || '') !== 0) {
				continue;
			}

			listItems[i].classList.add('is-current');
			return;
		}
	}
}

window.addEventListener('DOMContentLoaded', () => {
	new HeaderModule();
});
