import { Visitor } from './../JS/Classes/Visitor.js';
/**
 * @author Danko Adamczyk <danko@studyportals.com>
 * @version 1.0.0
 * @copyright © 2016 StudyPortals B.V., all rights reserved.
 */

window.addEvent('domready', function () {
	'use strict';

	/**
	 * User object.
	 *
	 * @constructor
	 * @extends AuthenticationLayer
	 */
	class User extends Visitor {
		constructor() {
			super();

			this.type = 'user';
		}

		isFormerEmployee() {
			const userData = this.getMe();
			return userData.groups.includes('Former Employees');
		}
	}

	window.Endor.User = new User();

	if (window.Endor.User.getMe()) {
		window.Endor.User.heartbeat();
	}
});
