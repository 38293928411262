import './global';

class RollbarLogger {
	public logCritical(message: string, extra?: string | Error | Record<any, any> | Date | any[] | undefined): void {
		if (!window.Rollbar) {
			console.error('Rollbar not available on window', window.Rollbar);
			return;
		}

		if (extra === undefined) {
			window.Rollbar.critical(message);
		} else {
			window.Rollbar.critical(message, extra);
		}
	}

	public logError(message: string, extra?: string | Error | Record<any, any> | Date | any[] | undefined): void {
		if (!window.Rollbar) {
			console.error('Rollbar not available on window', window.Rollbar);
			return;
		}

		if (extra === undefined) {
			window.Rollbar.error(message);
		} else {
			window.Rollbar.error(message, extra);
		}
	}

	public logWarning(message: string, extra?: string | Error | Record<any, any> | Date | any[] | undefined): void {
		if (!window.Rollbar) {
			console.error('Rollbar not available on window', window.Rollbar);
			return;
		}

		if (extra === undefined) {
			window.Rollbar.warn(message);
		} else {
			window.Rollbar.warn(message, extra);
		}
	}
}

export default new RollbarLogger();
