import { Shared } from './../../../../../../../Templates/Shared/JS/Shared.js';
import { AuthDriverServiceLayer } from './../../../../../../../Packages/Endor/Modules/Auth/User/JS/Classes/AuthDriverServiceLayer.js';

('use strict');

/**
 * @extends AuthDriverServiceLayer
 */
export class AuthenticationLayer extends AuthDriverServiceLayer {
	/**
	 * @constructor
	 */
	constructor() {
		super();

		this.instance_id = this.Hook.getAttribute('data-instance_id');
	}

	/**
	 * Login the user with the given password.
	 *
	 * @param {String} $username
	 * @param {String} $password
	 * @param {Object} [options]
	 * @param {Function} options.onSuccess
	 * @param {Function} options.onFailure
	 * @constructor
	 */
	login($username, $password, options) {
		options = options || {};

		var $self = this;

		this._loginSl($username, $password, {
			onSuccess(data) {
				$self._createSessionInUniversityAdmin($username, data.token, options);
			},
			onFailure() {
				$self._clearMe();

				if (typeof options.onFailure === 'function') {
					options.onFailure();
				}
			}
		});
	}

	/**
	 * Send an heartbeat to extend your session.
	 */
	heartbeat() {
		var $self = this;

		var $Request = new Request({
			method: 'get',
			url: Shared.getBaseUrl() + 'Ajax.php',
			data: {
				instance_id: this.instance_id,
				action: 'heartbeat'
			},
			onSuccess($response) {
				var $Me = JSON.decode($response);
				$self._updateMe($Me);
			},
			onFailure() {
				$self._clearMe();
			}
		});

		$Request.xhr.withCredentials = true;
		$Request.send();
	}

	/**
	 * Get Information about my session in the Portal.
	 *
	 * @returns {Object}
	 */
	getMe() {
		return JSON.decode(sessionStorage.getItem('me'));
	}

	/**
	 * Log me out.
	 */
	logout() {
		this._clearMe();
		window.location = '?logout=true';
	}

	/**
	 * Create a session in UniversityAdmin.
	 *
	 * @param {String} $username
	 * @param {String} $token
	 * @param {Object} options
	 * @param {Function} [options.onSuccess]
	 * @param {Function} [options.onFailure]
	 * @protected
	 */
	_createSessionInUniversityAdmin($username, $token, options) {
		var $self = this;

		options = options || {};

		var $Request = new Request({
			method: 'post',
			url: Shared.getBaseUrl() + 'Ajax.php',
			data: {
				instance_id: this.instance_id,
				action: 'login',
				username: $username,
				token: $token
			},
			async onSuccess(response) {
				var $Me = JSON.decode(response);
				$self._updateMe($Me);

				if (typeof options.onSuccess === 'function') {
					await options.onSuccess($self.getMe());
				}

				window.CommonsModule.trackEventForHouseStarkOnCurrentPage(
					'UniversityAdmin',
					'Stability',
					['success'],
					'Login',
					'UniAdminSessionCreation',
					$username
				);
			},
			onFailure(xhr) {
				window.CommonsModule.trackEventForHouseStarkOnCurrentPage(
					'UniversityAdmin',
					'Stability',
					['error'],
					'Login',
					'UniAdminSessionCreation',
					$username
				);

				const statusCode = xhr.status;
				const response = xhr.responseText;
				const sessionFailedToInitializeText =
					'Unable to return Page from Session, element not found in' + ' session-state.';
				if (statusCode === 500 && response.includes(sessionFailedToInitializeText)) {
					const errorSpanElement = document.getElementById('invalid-session');
					if (errorSpanElement) {
						errorSpanElement.classList.remove('Hidden');
					}
					const loginButton = document.querySelector('.ladda-button.Button.Attention');
					if (loginButton) {
						loginButton.disabled = true;
					}
				}

				options.onFailure();
			}
		});

		$Request.xhr.withCredentials = true;
		$Request.send();
	}

	/**
	 * Update the user information.
	 *
	 * @param {Object} $Me
	 * @private
	 * @returns void
	 */
	_updateMe($Me) {
		var $now = new Date();
		var $expires = $now.setMinutes($now.getMinutes() + 55);
		$Me['session_expires_at'] = new Date($expires);

		sessionStorage.setItem('me', JSON.encode($Me));
	}

	/**
	 * Remove the user information.
	 *
	 * @private
	 * @returns void
	 */
	_clearMe() {
		sessionStorage.removeItem('me');
	}
}

(function (_w) {
	_w.AuthenticationLayer = new AuthenticationLayer();
})(window);
