/**
 * @file Shared.js
 * For portal-wide usage; requires MooTools.
 *
 * @author Brian Yao <brian@studyportals.com>
 * @author Rob Janssen <rob@studyportals.com>
 * @author Thijs Putman <thijs@studyportals.com>
 * @author Stefan Klokgieters <stefan@studyportals.com>
 * @author Rob van den Hout <vdhout@studyportals.com>
 * @author Danko Adamczyk <danko@studyportals.com>
 * @copyright © 2011-2016 StudyPortals B.V., all rights reserved.
 * @version 1.4.1
 */

'use strict';

/**
 * Container for generic, portal-wide, "stuff".
 */

export let Shared;

if (typeof window.Shared === 'undefined') {
	Shared = {};
} else {
	Shared = window.Shared;
}

Shared.getBaseUrl = function () {
	// Get the base URL
	const currentURI = new URL(window.location);
	let url = currentURI.protocol;
	url += '//';
	url += currentURI.hostname;

	if (['80', '443'].indexOf(currentURI.port) === -1) {
		url += ':' + currentURI.port;
	}

	// Add the last slash
	url += '/';

	return url;
};

/**
 * Adds a new event listener that will fire an event based on requestAnimationFrame
 *
 * RequestAnimationFrame tells the browser to update an animation
 * (the DOM in many of our use cases) before the next repaint. This means that
 * the resize event will only be called at every animation frame.
 * Very efficient with no timeouts required!
 *
 * This can be used to throttle a variety of events including mouse clicks for
 * drawing!
 *
 * To initialise the event simply do e.g. Shared.throttle("resize", window, fn)
 *
 * @param {String} type	- the type of event to add
 * @param {Object} attachTo - the object onto which to attach the event listener
 * @param {Function} callback - the code to execute after an animation frame
 * @return void
 */

Shared.throttle = function (type, attachTo, callback) {
	attachTo = attachTo || window;
	var running = false;

	var eventCallback = function () {
		if (running) {
			return;
		}

		running = true;

		window.requestAnimationFrame(function () {
			callback();
			running = false;
		});
	};

	attachTo.addEvent(type, eventCallback);
};

/**
 * Function to convert a float to a string, formatted like the currency set
 * in $currency Includes a (poor) fallback for browsers without proper
 * toLocaleString support (Safari)
 *
 * @param {float} $amount - Number that should be converted to a currency-string
 * @param {string} $currency - ISO code for the currency to convert to
 * @param {string} [$symbol] - Fallback symbol for safari
 * @param {integer} decimal - Amount of digits to show behind the decimal point
 * @param {string} [$currency_symbol='yes'] - Do we want a currency symbol in the returned string?
 * @returns {string}
 */

Shared.formatAsLocalisedCurrency = function ($amount, $currency, $symbol, decimal, $currency_symbol) {
	// If no decimal specified then set a fallback.
	decimal = decimal || 0;

	var $options = {
		minimumFractionDigits: decimal,
		maximumFractionDigits: decimal
	};

	var $options_fallback = {
		decimals: decimal
	};

	if (!$currency_symbol || $currency_symbol === 'yes') {
		$options.currency = $currency;
		$options.style = 'currency';
		$options_fallback.prefix = $symbol + ' ';
	}

	var $newHtml = parseFloat($amount).toLocaleString('nu', $options);

	/**
	 * Check if amount is converted, or still a float (Safari does not have a
	 * proper implementation for toLocaleString and we don't like to just show
	 * floats)
	 */

	if (String($amount) === $newHtml) {
		console.info(
			'Shared.formatAsLocalisedCurrency: toLocaleString seems ' + 'to have failed, converting manually...',
			$newHtml
		);

		$newHtml = $amount.format($options_fallback);
	}

	return $newHtml;
};

/**
 * All header dropDown instances stored here
 * Used to iterate over each instance and hide them
 * This way can check if instance should be closed or not
 */

Shared.headerDropDownInstances = [];

/**
 * Run code only for a certain breakpoint.
 *
 * This code checks if the specified breakpoint(s) is/are active at the current
 * time and executes the callback provided if at least one match is found.
 * The function will also return true or false depending on if a match is found.
 *
 * To use this function  make sure to bind 'this' to the callback to keep
 * your chosen execution context.
 *
 * You can use the breakpoints:
 * 'Small', 'Medium', 'Large', 'ExtraLarge'
 * If no breakpoint is specified the code will not execute.
 * A warning will be triggered for this.
 *
 * @param {String|Array} $breakPoints - a list of breakpoints
 * @param {Function} [callback] - the function to call if the chosen breakpoint is active
 * @return {Boolean} match - whether or not the passed in breakpoints match or not
 */

Shared.breakpoints = function ($breakPoints, callback) {
	// Blank array of media query results
	var $mediaQueries = [];
	var match = false;

	// For each breakpoint given, check if it is currently active and add to array
	if (typeOf($breakPoints) === 'array') {
		$breakPoints.forEach(function ($breakPoint) {
			$mediaQueries.push(isActiveBreakpoint($breakPoint));
		});
	} else {
		$mediaQueries.push(isActiveBreakpoint($breakPoints));
	}

	/**
	 * Returns a boolean whether or not the window is currently a given size.
	 *
	 * @param $size
	 * @returns {Boolean}
	 */
	function isActiveBreakpoint($size) {
		var $mediaMatch = '';

		switch ($size) {
			case 'ExtraLarge':
				$mediaMatch = 'all and (min-width: 1281px)';
				break;

			case 'Large':
				$mediaMatch = 'all and (min-width: 769px) and (max-width: 1280px)';
				break;

			case 'Medium':
				$mediaMatch = 'all and (min-width: 481px) and (max-width: 768px)';
				break;

			case 'Small':
				$mediaMatch = 'all and (max-width: 480px)';
				break;

			// Temporary until we switch everything to the narrow grid.
			case 'ExtraLargeNarrow':
				$mediaMatch = 'all and (min-width: 1025px)';
				break;
			case 'LargeNarrow':
				$mediaMatch = 'all and (min-width: 769px) and (max-width: 1024px)';
				break;

			default:
				$mediaMatch = 'none';
				console.warn('Invalid breakpoint of size: ' + $size);
				break;
		}

		return window.matchMedia($mediaMatch).matches;
	}

	// Looks through the array of media queries to see if one matches.
	// As soon as a match has been found, executes the callback and breaks the loop.
	$mediaQueries.some(
		function ($queryBool) {
			if ($queryBool) {
				if (callback) {
					callback.apply(this);
				}

				match = true;
				return true;
			}
		}.bind(this)
	);

	return match;
};

/**
 * Lazy load a script and append it to the bottom of the body.
 *
 * This can be used from any module in order to load a script when the element
 * desired scrolls into view. Uses the MooTools More Asset type to load
 * javascript on demand in an async fashion.
 *
 * @param {String} $src - the source of the script to inject
 * @param {Element} $element - the element that must be in view for the script to be run
 * @param {Function} [callback] - the function to execute once the script has loaded
 * @param {Number} [$detectionPadding] - an optional number of padding
 */

Shared.lazyLoadScript = function ($src, $element, callback, $detectionPadding) {
	/*
	 * This padding is to make sure assets have (probably) loaded before you
	 * reach the module. This also prevents issues with resizing bringing the
	 * module into view.
	 */
	$detectionPadding = $detectionPadding || 400;
	var loaded = false;
	var injectScript;

	// Immediately execute the injectScript function just in case the asset
	// is already scrolled into the viewport.
	(injectScript = function () {
		//NOSONAR

		/*
		 * Detect if element has passed the boundary of the viewport minus
		 * some extra padding so script can be loaded already when reached
		 */
		if ($element.getBoundingClientRect().top > window.getSize().y + $detectionPadding) {
			return;
		}

		// Remove the event so that the asset is not loaded into the header
		// multiple times
		document.removeEvent('scroll', injectScript);

		// Trigger loaded to true.
		loaded = true;
		Asset.javascript($src, {
			async: true,
			onLoad: function () {
				callback ? callback() : null;
			}
		});
	})();

	// If the source has not been loaded yet attach the on scroll event.
	if (!loaded) {
		document.addEvent('scroll', injectScript);
	}
};

/**
 * Create a dark overlay to overshadow the page and highlight the mainElement
 *
 * @param [mainElement, fireEventElement]
 * mainElement is the element that needs to be highlighted
 */

Shared.overShadow = {
	mainElement: null,
	overlay: null,

	options: {},

	/*
	 * Creating the overlay element and adding the style
	 */
	init: function () {
		// create the overlay
		var $overlay = new Element('div', {
			id: 'semiTransparentOverlay'
		});

		// setting the style
		$overlay.setStyles({
			display: 'none',
			position: 'fixed',
			top: 0,
			right: 0,
			left: 0,
			bottom: 0,
			height: '100%',
			minHeight: '100%',
			backgroundColor: 'rgba(0, 0, 0, 0.3)',
			zIndex: 100
		});

		$overlay.fade('hide');

		// adding event to destroy it
		$overlay.addEvent('click', function () {
			Shared.overShadow.hide();
		});

		Shared.overShadow.overlay = $overlay;
	},

	/**
	 * Shoving the overlay and setting the style of the mainElement.
	 *
	 * Be sure that the element has position at least relative otherwise the
	 * focus will not work.
	 *
	 * @param {Element} mainElement
	 * @param {Object} [options] - onHide (function to be run when overlay is being hidden)
	 * @return void
	 */

	show: function (mainElement, options) {
		// for one column view don't apply te overlay
		if (Shared.breakpoints('Small')) {
			return;
		}

		if (!mainElement) {
			console.warn('Shared.showOverShadow: no mainElements found');
			return;
		}

		// Give the possibility to add callback functions

		options = options || {};
		Shared.overShadow.options = options;

		Shared.overShadow.mainElement = mainElement;

		Shared.overShadow.init();

		if ($('semiTransparentOverlay')) {
			console.info('a semiTransparentOverlay already exists');
			return;
		}

		var $overlay = Shared.overShadow.overlay;

		document.body.appendChild($overlay);

		// fade in the overshadow
		$overlay.setStyle('display', 'block');
		$overlay.fade('in');

		// Storing style of mainElement to be able to revert it later
		Shared.overShadow.mainElement.styles = mainElement.getStyles('position', 'zIndex');

		mainElement.setStyle('z-index', 101);
	},

	/**
	 *  Destroying the overlay, reverting the style of mainElement
	 */

	hide: function () {
		if (!Shared.overShadow.mainElement) {
			console.warn('Shared.overShadow.hide: no mainElement found');
			return;
		}
		var $mainElement = Shared.overShadow.mainElement;

		var $overlay = $('semiTransparentOverlay');

		if ($overlay) {
			$overlay.destroy();
		}

		$mainElement.setStyles(Shared.overShadow.mainElement.styles);

		// Run the onHide function if specified

		if (typeof Shared.overShadow.options.onHide === 'function') {
			Shared.overShadow.options.onHide();
		}
	}
};

/*//VERSION_TAG//*/

/**
 * Modify the MooTools request object to include instance_id in the get
 * parameters on post requests.
 */

var AjaxRequest = {
	init: function () {
		Request.implement({
			options: {
				onRequest: function () {
					var instance_id = this.options.data.instance_id;
					if (typeof instance_id === 'object') {
						instance_id = instance_id.value;
					}

					var url = this.options.url;
					var method = this.options.method;

					if (method.toLowerCase() === 'post' && instance_id) {
						if (url.contains('?')) {
							url += '&';
						} else {
							url += '?';
						}

						url += 'instance_id=' + instance_id;

						this.xhr.open(method.toUpperCase(), url, this.options.async, this.options.user, this.options.password);

						// This code needs to be updated if mootools is updated.

						Object.each(
							this.headers,
							function (value, key) {
								try {
									this.xhr.setRequestHeader(key, value);
								} catch (e) {
									this.fireEvent('exception', [key, value]);
								}
							},
							this
						);
					}
				}
			}
		});
	}
};

Element.implement({
	/**
	 * Rewrites a number so it can be displayed as a localised value.
	 *
	 * <b>Input:</b>
	 * <span class="LocaleNumber" data-decimals="2">10000</span>
	 *
	 * <b>Output:</b>
	 * <span class="LocaleNumber">10,000</span>
	 *
	 * @return {HTMLElement}
	 */

	formatAsNumber: function () {
		var decimals = this.get('data-decimals') || 0;
		var $number = this.get('html').toFloat();

		// Check for invalid usage.
		if (isNaN($number)) {
			console.warn('Shared.formatAsNumber: innerHTML of element is not an float.', this);
			return this;
		}

		this.set(
			'html',
			$number.toLocaleString('nu', {
				style: 'decimal',
				minimumFractionDigits: decimals,
				maximumFractionDigits: decimals
			})
		);

		return this;
	},

	/**
	 * Rewrites a date or time so it can be displayed as a localised value.
	 *
	 * Define new date formats here:
	 * 	- time-offset: like %Z %z however displays GMT +01:00 with a colon
	 *
	 * <b>Input:</b>
	 * <time datetime="2015-03-31 14:00:00"
	 * 		 data-format="%H:%M"
	 * 		 data-format-title="%c">2015-03-31 14:00:00</span>
	 *
	 * <b>Output:</b>
	 * <time datetime="2015-03-31 14:00:00"
	 * 		 data-format="%H:%M"
	 * 		 title="Tu Mar 31 14:00:00 2015">16:00</span>
	 *
	 * By adding the class "NoZulu" to the <time> element, the time component
	 * will be ignored and no timezone calculations will be performed.
	 *
	 * @see http://mootools.net/more/docs/1.6.0/Types/Date#Date:format
	 *
	 * @return {HTMLElement}
	 */

	formatAsDateTime: function () {
		Date.defineFormat('time-offset', function (date) {
			var timeDifference = date.format('%z');
			var timeZone = date.format('%Z');

			return timeZone + ' ' + timeDifference.substr(0, 3) + ':' + timeDifference.substr(3);
		});

		var $dateTime = this.get('datetime').trim().toString();
		if ($dateTime === '') {
			console.warn('Shared.formatAsDateTime: No values specified', $dateTime);
			return this;
		}

		var $dataFormat = this.get('data-format');
		if (!$dataFormat) {
			console.warn('Shared.formatAsDateTime: No data-format attribute available', this);
			return this;
		}

		var $format = $dataFormat.trim().toString();
		if (!$format || $format === '') {
			console.warn(
				'Shared.formatAsDateTime: No date or time formatting ' +
					'present, make sure that attribute data-format is present and ' +
					'not empty.'
			);
			return this;
		}

		/*
		 UTC time is the same as Zulu time so we explicitly want to tell that this
		 string is Zulu time.
		 @see https://en.wikipedia.org/wiki/ISO_8601#UTC
		 */

		var $properTime = $dateTime + 'Z';

		// Override the Zulu time behaviour in case the time is explicitly local.
		if (this.hasClass('NoZulu')) {
			$properTime = $dateTime;
		}

		var $formatted = Date.parse($properTime).format($format);

		if ($formatted === 'Invalid date') {
			return this;
		}

		this.set('text', $formatted);

		var $dataFormatTitle = this.get('data-format-title');
		if ($dataFormatTitle) {
			this.set('title', Date.parse($properTime).format($dataFormatTitle));
		}

		return this;
	}
});

/**
 * Adding clone function to the function prototype.
 * @returns {Function}
 *
 * @see http://stackoverflow.com/questions/1833588/javascript-clone-a-function
 */

Function.prototype.clone = function () {
	var that = this;
	var temp = function () {
		return that.apply(this, arguments);
	};
	for (var key in this) {
		if (this.hasOwnProperty(key)) {
			temp[key] = this[key];
		}
	}
	return temp;
};

document.addEvent('domready', function () {
	/*
	 * Bootstrap "document.body" for use with IE <= 8 and MooTools.
	 *
	 * In order for Internet Explorer 8 (or lower) to work reliably within our
	 * portals, the below code should be the *first* piece of code added to the
	 * "domready" event!
	 *
	 * Old versions of Internet Explorer do not automatically extend Element (of
	 * which "document.body" is the first and foremost) with all of MooTools'
	 * Element methods. Since all other browsers do support this and the call to
	 * "document.body" is rather fundamental, our existing code-base/practices do
	 * not take this into account. Rather, a compatibility fix is provided here.
	 * If IE8 ever becomes a thing of the past, this code can safely be removed.
	 */

	$(document.body);

	AjaxRequest.init();

	/* a general wrapper that handles all the uses of the mootools OverText
	 * Class.
	 *  - also overules the standard use of the alt or title tag as the
	 *  data source for the overtext displayed to the custom attribute
	 *  data-OverText (conform HTML5 standards).
	 *  to use simply apply the custom attribute data-OverText.
	 */

	(function () {
		var textFieldsWithOvertext = $$('[data-OverText]');

		if (textFieldsWithOvertext.length > 0) {
			console.warn("Please don't use this anymore, use placeholder attributes.");
			// apply all the overview instances:
			textFieldsWithOvertext.each(function (textField) {
				var storedOverText = textField.get('data-overtext');
				new OverText(textField, { textOverride: storedOverText });
				//NOTE: instances of OverText are available as the Global object OverText provided by MooTools
			});
		}
	})();

	if (typeof imgix === 'object') {
		var imgixOptions = {
			onChangeParamOverride: function () {
				return {
					auto: 'compress',
					dpr: '1'
				};
			},
			fluidClass: 'imgix-fluid',
			lazyLoad: true,
			maxHeight: 400,
			maxWidth: 860,
			pixelStep: 50
		};

		var mediaThumbsOptions = {
			onChangeParamOverride: function () {
				return {
					auto: 'compress',
					fit: 'crop',
					crop: 'entropy',
					dpr: '1'
				};
			},
			fluidClass: 'mediaThumb',
			lazyLoad: true,
			fitImgTagToContainerHeight: true,
			pixelStep: 50
		};

		if (imgix.onready) {
			imgix.onready(function () {
				imgix.fluid(imgixOptions);
				imgix.fluid(mediaThumbsOptions);
			});
		} else {
			console.error('Shared.js: imgix onready function does not exist');
		}
	}

	document.addEvent('redirect', function (uri) {
		console.info('Requested redirect to: ' + uri);

		if (window.location !== uri) {
			window.location = uri;
		}
	});
});

window.Shared = Shared;
