class CookieHandler {
	public retrieveTokenIdCookie(): string {
		return this.retrieveCookieByName('hs-sso-token-id');
	}

	public retrieveSessionExpirationTimeCookie(): string {
		return this.retrieveCookieByName('UniversityAdmin_SID_expiration');
	}

	public retrieveRefreshTokenCookie(): string {
		return this.retrieveCookieByName('hs-sso-token-refresh');
	}

	public retrieveUserNameFromCookie(token: string): string {
		const tokenParts = token.split('.');
		if (tokenParts.length < 2) {
			return '';
		}

		const base64Url = tokenParts[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split('')
				.map((c) => {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);

		const nameProperty = 'cognito:username';
		const jwt = JSON.parse(jsonPayload) as { [nameProperty]?: string };
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
		return jwt[nameProperty] ? (jwt[nameProperty] as string) : '';
	}

	private retrieveCookieByName(cookieName: string): string {
		const partsOfCompleteCookieString = document.cookie.split(`${cookieName}=`);
		if (partsOfCompleteCookieString.length !== 2) {
			return '';
		}

		const remainingCookieParts = partsOfCompleteCookieString[1].split(';');
		return remainingCookieParts[0];
	}
}

export default new CookieHandler();
