/**
 * Returns the first item from the Array, if the array is empty return null.
 *
 * @returns {*}
 */
Array.prototype.firstOrDefault = function () {
	if (this.length > 0) {
		return this[0];
	}

	return null;
};
