/**
 * @file Parameters.js
 *
 * @author Rob Janssen <rob@studyportals.com>
 * @author Johan Voorhout <johan@studyportals.com>
 * @version 1.3.0
 * @copyright © 2014-2016 StudyPortals B.V., all rights reserved.
 */

/**
 * Load abbreviations in local storage.
 */
import { DataStorage } from './../../Shared/JS/Storage';

var Parser = {
	/**
	 * Initialise the local storage.
	 *
	 * @return void
	 */

	init: function (callback) {
		var trackingData = JSON.decode(Cookie.read('StudyPortals-trck'));

		// Defensive check for SteXX
		if (trackingData === null) {
			return;
		}

		var rdf = trackingData.service_url + 'meta/parameters/?format=json';

		new Request.JSON({
			url: rdf,
			method: 'get',
			async: false,
			onSuccess: function (json) {
				var dict = {};
				dict.aliases = {};
				dict.types = {};
				json.alias.each(function (alias) {
					var data = alias['@attributes'];
					dict.aliases[data['long']] = data['short'];
					dict.types[data['long']] = data['type'];
				});

				var encoded = JSON.encode(dict);
				DataStorage.store('Parameters', encoded, 3600 * 6);

				callback(dict);
			}
		}).send();
	}
};

export let Parameters = {
	aliases: {},
	types: {},

	/**
	 * Init is always only called once - on page load.
	 *
	 * @returns void
	 */

	init: function () {
		// Check the local storage's date.

		var callback = function (data) {
			Parameters.aliases = data.aliases;
			Parameters.types = data.types;
		};

		if (!DataStorage.retrieve('Parameters')) {
			console.debug('Parameters not found in local storage.');
			Parser.init(callback);
			return;
		}

		var encoded = DataStorage.retrieve('Parameters');
		var decoded = JSON.decode(encoded);

		if (decoded === null || !decoded.aliases) {
			Parser.init(callback);
			return;
		}

		Parameters.aliases = decoded.aliases;
		Parameters.types = decoded.types;
	},

	/**
	 * Turn a parameter array into a string.
	 *
	 * @param {object} parameterArray
	 * @return string
	 */

	alias: function (parameterArray) {
		if (typeof parameterArray !== 'object') {
			console.warn('Parameter array is not an array.');
			return '';
		}

		if (parameterArray.length === 0) {
			console.warn('No parameters to alias.');
			return '';
		}

		var aliased = [];

		parameterArray = this._sort(parameterArray);

		Object.each(
			parameterArray,
			function (value, key) {
				switch (this._getType(key)) {
					case 'string[]':
						try {
							aliased.push(this._serializeStringArray(key, value));
						} catch (err) {
							console.warn('Serialization for ' + key + ' failed: ' + err);
						}
						break;

					case 'integer[]':
						try {
							aliased.push(this._serializeIntegerArray(key, value));
						} catch (err) {
							console.warn('Serialization for ' + key + ' failed: ' + err);
						}
						break;

					case 'string':
						if (typeof value !== 'string') {
							value = '';
						}

						value = value.trim();

						if (value !== '') {
							aliased.push(this._getShortName(key) + '-' + value);
						} else {
							console.warn('Value "' + value + '" in key ' + key + ' is an empty string!');
						}
						break;

					case 'integer':
						value = value.toInt();

						if (!isNaN(value)) {
							aliased.push(this._getShortName(key) + '-' + value);
						} else {
							console.warn('Value ' + value + ' in key ' + key + ' is not a number!');
						}
						break;

					case 'boolean':
						if (value) {
							aliased.push(this._getShortName(key) + '-' + '1');
						} else {
							aliased.push(this._getShortName(key) + '-' + '0');
						}
						break;

					case 'timestamp':
						try {
							value = this._parseTimeStamp(value);
							aliased.push(this._getShortName(key) + '-' + value);
						} catch (err) {
							console.warn('Could not parse timestamp for ' + key);
						}

						break;

					default:
						console.warn('Could not resolve type for ' + key);
						break;
				}
			}.bind(this)
		);

		if (aliased.length === 0) {
			console.warn('Could not generate aliased string.');
			return '';
		}

		return aliased.join('|');
	},

	/**
	 * Turn a string into a parameter array.
	 *
	 * @param {string} aliasedString
	 * @return object
	 * @throws err
	 */

	unalias: function (aliasedString) {
		if (typeof aliasedString !== 'string') {
			console.warn('Cannot un-alias string!');
			throw err;
		}

		// Sorting works by virtue of the format of the aliased string.

		var components = aliasedString.split('|');
		components.sort();

		var parameterArray = {};

		Array.each(
			components,
			function (item) {
				var composite = item.split('-');
				var alias = composite.shift();
				var value = composite.join('-');

				var longName = this._getLongName(alias);

				if (longName === '') {
					console.warn('Full name for ' + alias + ' not found!');
					return;
				}

				var type = this._getType(longName);

				if (type === '') {
					console.warn('Type for ' + longName + ' not found!');
					return;
				}

				switch (type) {
					case 'string[]':
						try {
							parameterArray[longName] = this._unserializeStringArray(value);
						} catch (err) {
							console.warn('Could not unserialize ' + longName);
							return;
						}
						break;

					case 'integer[]':
						try {
							parameterArray[longName] = this._unserializeIntegerArray(value);
						} catch (err) {
							console.warn('Could not unserialize ' + longName);
							return;
						}
						break;

					case 'string':
						parameterArray[longName] = value;
						break;

					case 'integer':
						if (!isNaN(value.toInt())) {
							parameterArray[longName] = value;
						}
						break;

					case 'boolean':
						if (value) {
							parameterArray[longName] = true;
						} else {
							parameterArray[longName] = false;
						}
						break;

					default:
						console.warn(longName + ' not unserialized, ' + 'type ' + type + ' not implemented.');
						break;
				}
			}.bind(this)
		);

		return parameterArray;
	},

	/**
	 * Sort parameters so they're always in the same order.
	 *
	 * @param {object} parameterArray
	 * @returns {object}
	 */

	_sort: function (parameterArray) {
		var keys = [];

		Object.each(parameterArray, function (value, key) {
			keys.push(key);
		});

		keys.sort();

		var sorted = {};

		Array.each(keys, function (item) {
			sorted[item] = parameterArray[item];
		});

		return sorted;
	},

	/**
	 * Unserialize a serialized string array.
	 *
	 * @param {string} value
	 * @returns object
	 * @throws err
	 */

	_unserializeStringArray: function (value) {
		var split = value.split(',');

		var clean = [];

		Array.each(split, function (item) {
			var str = item.trim();

			if (str != '') {
				clean.push(str);
			}
		});

		if (clean.length === 0) {
			throw err;
		}

		return clean;
	},

	/**
	 * Unserialize a serialized integer array.
	 *
	 * @param {string} value
	 * @returns object
	 * @throws err
	 */

	_unserializeIntegerArray: function (value) {
		var split = value.split(',');

		var clean = [];

		Array.each(split, function (item) {
			var num = item.toInt();

			if (!isNaN(num)) {
				clean.push(num);
			}
		});

		if (clean.length === 0) {
			throw err;
		}

		return clean;
	},

	/**
	 * Serialize an integer array.
	 *
	 * @param {string} key
	 * @param {string} value
	 * @returns string
	 * @throws err
	 */

	_serializeIntegerArray: function (key, value) {
		if (this._getShortName(key) === '') {
			console.warn('No alias found for ' + key + '!');
			throw err;
		}

		if (Object.prototype.toString.call(value) !== '[object Array]') {
			console.warn('Not an array!');
			throw err;
		}

		if (value.length === 0) {
			console.warn('Empty array!');
			throw err;
		}

		var clean = [];

		Array.each(value, function (item) {
			var num = item.toInt();

			if (!isNaN(num)) {
				clean.push(num);
			}
		});

		if (clean.length === 0) {
			console.warn('Empty array!');
			throw err;
		}

		return this._getShortName(key) + '-' + clean.join(',');
	},

	/**
	 * Serialize a string array.
	 *
	 * @param {string} key
	 * @param {string} value
	 * @returns string
	 * @throws err
	 */

	_serializeStringArray: function (key, value) {
		if (this._getShortName(key) === '') {
			console.warn('No alias found for ' + key + '!');
			throw err;
		}

		if (Object.prototype.toString.call(value) !== '[object Array]') {
			console.warn('Not an array!');
			throw err;
		}

		if (value.length === 0) {
			console.warn('Empty array!');
			throw err;
		}

		var clean = [];

		Array.each(value, function (item) {
			var str = item.trim();

			if (str != '') {
				clean.push(str);
			}
		});

		if (clean.length === 0) {
			console.warn('Empty array!');
			throw err;
		}

		return this._getShortName(key) + '-' + clean.join(',');
	},

	/**
	 * Determine the key type for a given long name.
	 *
	 * @param key
	 * @returns {string}
	 */

	_getType: function (key) {
		if (typeof this.types[key] !== 'undefined') {
			return this.types[key];
		}

		console.warn(key + ' is unsupported');
		return '';
	},

	/**
	 * Get the alias for a given key.
	 *
	 * @param {string} key
	 * @returns {string}
	 */

	_getShortName: function (key) {
		if (typeof this.aliases[key] !== 'undefined') {
			return this.aliases[key];
		}

		console.warn('Could not find key alias for ' + key);
		return '';
	},

	/**
	 * Get the full name for a given key.
	 *
	 * @param {string} alias
	 * @returns string
	 */

	_getLongName: function (alias) {
		var longName = '';

		Object.each(this.aliases, function (value, key) {
			if (value === alias) {
				longName = key;
			}
		});

		return longName;
	},

	/**
	 * Parses a timestamp?
	 *
	 * @param {string} value
	 * @returns {string}
	 */

	_parseTimeStamp: function (value) {
		value = value.trim();

		// Defaults.

		var hour = 0;
		var minute = 0;
		var second = 0;

		// In Javascript, the months start at 0.
		var month = 0;
		var day = 1;

		// Year should always be specified.
		var DateTime = new Date();
		var year = DateTime.getFullYear();

		var timezone = DateTime.format('%z');

		DateTime.setSeconds(second.toInt());

		// It is assumed that this is already in the correct format.

		if (value.length === 19) {
			timezone = value.substr(14, 5);
		}

		// Contains a non-normalized timezone.

		if (value.length === 18) {
			var offsetHours = value.substr(14, 2).toInt();

			// Nepal has weird timezones.

			var offsetMinutes = value.substr(16, 2).toInt();

			var Afternoon = new Date();
			Afternoon.setHours(12, 0, 0);

			var Deviation = new Date();
			Deviation.setHours(offsetHours, offsetMinutes, 0);

			var interval = Afternoon.diff(Deviation, 'minute');

			var sign = '+';
			if (interval < 0) {
				sign = '-';
			}

			interval = Math.abs(interval);

			offsetMinutes = interval % 60;
			offsetHours = Math.floor(interval / 60);

			offsetMinutes = offsetMinutes.toString().pad(2, '0', 'left');
			offsetHours = offsetHours.toString().pad(2, '0', 'left');

			timezone = sign + offsetHours + offsetMinutes;
		}

		switch (value.length) {
			case 19:
			case 18:

			case 14:
				second = value.substr(12, 2);

			case 12:
				minute = value.substr(10, 2);

			case 10:
				hour = value.substr(8, 2);

			case 8:
				day = value.substr(6, 2);

			case 6:
				month = value.substr(4, 2);

				// Javascript starts counting at 0 for months.
				month = month.toInt() - 1;

			case 4:
				year = value.substr(0, 4);
				break;

			default:
				throw new err('Invalid length for datetime, ' + 'expecting 4, 6, 8, 10, 12, 14, 16, 18 or 19');
		}

		DateTime.setHours(hour.toInt());
		DateTime.setMinutes(minute.toInt());
		DateTime.setSeconds(second.toInt());

		DateTime.setFullYear(year.toInt());
		DateTime.setDate(day.toInt());
		DateTime.setMonth(month.toInt());

		// Equivalent to PHP's YmdHisO.
		var format = '%Y%m%d%H%M%S';

		var formatted = DateTime.format(format);

		// Add the normalized timezone again.

		return formatted + timezone;
	}
};

// Always initialise parameters.

Parameters.init();
